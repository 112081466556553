.container {
    display: inline-block;

    .label {
        display: block;
        margin-bottom: 12px;
        margin-left: 6px;
        font-size: 14px;
    }

    .cost-range {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cost-input {
            width: 45%;
        }
    }
}
