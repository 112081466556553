.board-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 50px;
  margin-top: 20px;
  font-size: 16px;
  border: 1px solid #efefef;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(odd) {
      background: #d8d8d8;
    }

    &:nth-child(even):hover {
      color: var(--lotte-red);
      cursor: pointer;
    }
  }
}

.title {
  height: 50px;
  background-color: #d8d8d8;
  border-top: none;
}

.merge-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  border: 1px solid #efefef;

  .merge-list {
    width: 100%;

    .merge-item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      cursor: pointer;
      border-bottom: 1px solid #efefef;

      &:hover {
        color: var(--lotte-red);
      }

      .loading {
        margin-right: 10px;
      }
    }

    .no-hover {
      cursor: auto;

      &:hover {
        color: black;
      }
    }
  }
}

.checkbox {
  position: absolute;
  right: 78px;
  bottom: 18px;
  user-select: none;
}
