.input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  &:last-child {
    margin-bottom: 30px;
  }


  .input-name {
    width: 20%;
    font-size: 16px;
  }

  .input {
    flex: 1;
  }

  .select {
    width: 100%;
  }
}