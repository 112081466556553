.loading {
    width: 100%;
    height: 90%;
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-filter-area {
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
}

.form-container {
    width: 100%;
    margin-top: 26px;
    overflow: auto;

    .subtitle {
        width: 100%;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;

        .subtitle-text {
            margin-right: auto;
        }
    }

    .total-table {
        width: 100%;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 3fr;
        grid-template-rows: repeat(2, minmax(50px, 1fr));

        & > * {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border-bottom: 0.5px solid rgb(132, 132, 132);
        }

        .col {
            background-color: rgb(132, 132, 132);
            color: white;
        }
        & > :last-child {
            padding: 0 15px;
        }

        input {
            text-align: center !important;
        }
    }
}
