.contents {
  width: 100%;

  .row {
    display: flex;
    gap: 32px;
    width: 100%;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card {
    box-sizing: border-box;
    display: inline-block;
    padding: 16px 32px;
    background-color: #fff;
    border-radius: 8px;

    .card-title {
      margin-bottom: 18px;
      font-size: 22px;
    }

    &.w45p {
      flex: 45;
      width: 45%;
    }

    &.w55p {
      flex: 55;
      width: 55%;
    }

    &.w100p {
      flex: 100;
      width: 100%;
    }
  }
}

.penalty-chart {
  width: 100%;
  height: 160px;
  margin-bottom: 12px;
}

.table-container {
  $border-color: #ed5757;

  position: relative;
  height: 100%;
  overflow-y: auto;
  border: 1px solid $border-color;
  border-radius: 8px;

  .table {
    width: 100%;
    margin-top: -1px;
    border-collapse: collapse;
    background-color: #fff;

    th, td {
      padding: 8px;
      text-align: center;
      vertical-align: middle;
    }

    tr {
      border-bottom: 1px solid #fff;

      &:first-child {
        border-top: unset;
      }
    }

    td {
      font-weight: 300;
    }

    &.fixed {
      position: sticky;
      top: 0;
      left: 0;
      margin-top: 0;
      border-bottom: 1px solid #fff;

      th {
        color: #dfdfdf;
        background-color: #ed5757;
        //background-color: #e8b9b9;
      }
    }
  }
}

.elevator-table {
  height: 200px;
}

.check {
  display: flex;
  gap: 24px;

  .check-chart {
    flex: 1;
    height: 370px;
  }

  .check-table {
    width: 400px;
    height: 370px;
  }
}

.equipment {
  width: 100%;

  .chart-row {
    display: flex;
    width: 100%;
    height: 300px;
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    .chart {
      position: relative;
      flex: 1;
      align-self: center;
      height: 100%;

      label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.merge-table {
  height: 300px;
}

.cost {
  height: 300px;
}

.mobile-container {
  width: 100%;
  height: 100%;

  .button {
    display: block;
    width: 100%;
    padding: 48px 52px;
    margin-bottom: 32px;
    font-family: TheJamsil, sans-serif;
    font-size: 32px;
    text-align: left;
    background: #fff;
    border: none;
    border-radius: 24px;

    span {
      margin-left: 18px;
    }
  }
}