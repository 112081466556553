.container {
  width: 100%;

  .layout {
    width: 500px;
    height:  500px;
    margin: 120px auto 0;
    line-height: 500px;
    text-align: center;
    background-color: #fff;
    background-image: url("./qr-border.svg");
    background-repeat: no-repeat;
    background-size: contain;

    .qrcode {
      display: inline-block;
      margin-top: 30px;
    }
  }
}