
.content {
  margin-top: 20px;
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 15px;

    .name {
      font-size: 16px;
      width: 25%;
    }

    .input-box {
      width: 75%;

      .input {
        width: 100%;
        border-radius: 0;
      }

      .button {
        background-color: var(--button-signature-color);
        border: none;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: var(--button-signature-highlight-color);
        }
      }

      .address {
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        background-color: #efefef;
        font-weight: 300;
      }

      .unit {
        font-size: 16px;
        font-weight: 200;
      }

      .have-unit {
        span, input {
          border-radius: 0;
        }
      }
    }
  }
}

