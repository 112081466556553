.filter-inner {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;

    .label {
        display: block;
        margin-bottom: 12px;
        margin-left: 6px;
        font-size: 14px;
    }

    .button-container {
        display: flex;
        gap: 4px;
        align-self: flex-end;
        margin-left: auto;
    }
}

.table-container {
    width: 100%;
    margin-top: 26px;

    .table-row {
        cursor: pointer;
    }
}
