.button-container {
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: right;
}

.steps-container {
    margin-top: 24px;
    margin-bottom: 36px;
}

.steps-description-container {
    display: flex;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 36px;

    span {
        flex: 1;
        padding: 12px 6px;
        text-align: center;
        border: 2px solid var(--lotte-red);
        border-radius: 8px;
    }
}

.contents-container {
    box-sizing: border-box;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    transition: height 0.5s ease;
}

.date {
    display: flex;
    gap: 24px;
    align-items: flex-end;
}

.tag-container {
    margin-top: 24px;

    .tag {
        display: flex;

        .tags {
            flex: 1;
            font-size: 24px;
            line-height: 48px;
            text-align: center;
            border: 1px solid #ccc;
        }

        .month-tags {
            flex: 1;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            border: 1px solid #ccc;
            margin-bottom: 16px;
        }
    }
}

.time-container {
    margin-top: 24px;
}

.title {
    margin-bottom: 12px;
    margin-left: 4px;
    font-size: 14px;
}

.notiTimes {
    margin-top: 8px;
    margin-left: 22px;

    li {
        margin-bottom: 4px;
        list-style: disc;
    }
}

.date-tag-container {
    .date-group {
        .date-tags {
            width: 60px;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
        }
    }
}
