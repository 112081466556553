.container {
    width: 100%;
    height: 100%;

    .title {
        padding: 0 0 16px;
        margin: 0;
        font-size: 24px;
        line-height: 1.2;
    }

    .button {
        display: block;
        width: 100%;
        padding: 28px 52px;
        margin-bottom: 32px;
        font-family: TheJamsil, sans-serif;
        font-size: 28px;
        text-align: left;
        background: #fff;
        border: none;
        border-radius: 24px;

        span {
            margin-left: 18px;
        }
    }

    .list-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 28px 24px;
        background: #fff;
        border: none;
        border-radius: 24px;

        h2 {
            font-size: 20px;
        }

        ul {
            margin-top: 28px;

            li {
                padding: 24px 18px;
                font-size: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 0.5px #d0d0d0;
                background-color: #fafafa;
                label {
                    font-weight: 500;
                }
                span {
                    font-weight: 500;
                }
            }
            li + li {
                background-color: #fff;
                label {
                    font-weight: 300;
                }
                span {
                    font-weight: 300;
                    color: #d9534f;
                }
            }
        }
    }
}
