.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.table-container {
  width: 100%;
  margin-top: 26px;
}

.document {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150000;
  background-color: #5c5c5c70;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 80%;
    background-color: #FFF;
    transform: translate(-50%, -50%);
  }
}