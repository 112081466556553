.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.chart-container {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  gap: 12px;
  width: 100%;
  margin-top: 26px;


  .sankey-container {
    box-sizing: border-box;
    flex: 1;
    height: 180px;
    padding: 12px 12px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    .pie-container {
      .text {
        position: absolute;
        font-size: 12px;
      }

      width: 350px;
      overflow: visible;
      z-index: 19;
      box-sizing: border-box;
      height: 100%;
    }
  }
}

.table-container {
  position: relative;
  width: 100%;
  margin-top: 12px;

  .button-container {
    position: absolute;
    top: 18px;
    right: 12px;
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;

  .label {
    font-size: 14px;
  }
}
