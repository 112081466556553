.mobile-container {
  width: 100%;
  height: 100%;

  .button {
    display: block;
    width: 100%;
    padding: 28px 32px;
    text-align: left;
    background: #fff;
    border: none;
    border-radius: 24px;

    p {
      font-family: TheJamsil, sans-serif;
      font-size: 28px;
    }

    .count-text {
      margin-top: 4px;
      margin-right: 12px;
      font-family: TheJamsil, sans-serif;
      font-size: 14px;
    }
  }
}

.row-1 {
  display: flex;
  margin-bottom: 32px;
}

.row-2 {
  display: flex;
  gap: 20px;
  margin-bottom: 32px;
}