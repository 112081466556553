.inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
        font-size: 24px;
        line-height: 1.2;
    }

    .close-button {
        font-size: 28px;
        color: #686868;
    }

    .input {
        width: 75%;
        height: 100%;
        padding: 24px;
        font-size: 24px;
    }
    .input-reset-button {
        font-size: 16px;
        padding: 8px;
        background-color: #d0d0d0;
        color: white;
        border-radius: 50%;
    }

    .button {
        width: 22%;
    }
}

.inner-container + .inner-container {
    margin-top: 40px;
}

.list-container {
    box-sizing: border-box;
    margin-top: 40px;
    padding: 28px 24px;
    background: #fff;
    border-radius: 8px;

    h2 {
        font-size: 22px;
        margin-bottom: 20px;
        padding-right: 12px;
    }
    .count {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 400;
    }

    .table {
        tr,
        th,
        td {
            vertical-align: middle;
            padding: 18px 12px !important;
            font-size: 16px;
        }

        th {
            font-size: 18px;
            font-weight: 400 !important;
        }

        td {
            font-weight: 300;
        }
        tr:nth-child(even) {
            background-color: #fafafa;
        }
    }
}

// button module copy
.button {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 24px;
    font-family: TheJamsil, sans-serif;
    font-size: 25px;
    user-select: none;
    border-radius: 4px;
    outline: none;
    color: var(--button-signature-text-color);
    background-color: var(--button-signature-color);
    border: 1px solid var(--button-signature-color);

    &:hover {
        background-color: var(--button-signature-highlight-color);
        border-color: var(--button-signature-highlight-color);
    }
}
