.container {
  width: 100%;
  max-width: 100%;
  margin: 0 10px;
  height: 60%;
  max-height: 60%;
  background-color: #eeeeee;
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;

  .checkbox-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;

    .hidden {
      display: none;
    }
  }
}


.margin {
  margin: 0;
  height: 140px;
}
