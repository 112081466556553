.container {
  box-sizing: border-box;
  display: inline-block;
  padding: 6px 12px;
  margin: 0 2px;
  font-family: TheJamsil, sans-serif;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  outline: none;

  &.large {
    padding: 12px 24px;
    font-size: 18px;
  }

  &.fullWidth {
    width: 100%;
    margin: 0;
  }

  &.contained {

    &.primary {
      color: var(--button-primary-text-color);
      background-color: var(--button-primary-color);
      border: 1px solid var(--button-primary-highlight-color);

      &:hover {
        background-color: var(--button-primary-highlight-color);
        border-color: var(--button-primary-highlight-color);
      }
    }

    &.signature {
      color: var(--button-signature-text-color);
      background-color: var(--button-signature-color);
      border: 1px solid var(--button-signature-color);

      &:hover {
        background-color: var(--button-signature-highlight-color);
        border-color: var(--button-signature-highlight-color);
      }
    }
  }

  &.outlined {

    &.primary {
      color: var(--button-primary-text-color);
      background-color: transparent;
      border: 1px solid var(--button-primary-highlight-color);

      &:hover {
        background-color: var(--button-primary-highlight-color);
        border-color: var(--button-primary-highlight-color);
      }
    }

    &.signature {
      color: var(--button-signature-highlight-color);
      background-color: transparent;
      border: 1px solid var(--button-signature-color);

      &:hover {
        background-color: var(--button-signature-highlight-with-alpha-color);
        border-color: var(--button-signature-highlight-color);
      }
    }
  }

  &:active {
    border-color: transparent;
    box-shadow: inset 0 1px 6px #00000050;
  }

  &:disabled {
    color: var(--button-disabled-text-color);
    cursor: default;
    background-color: var(--button-disabled-color);
    border: 1px solid var(--button-disabled-border-color);
    outline: none;
    box-shadow: none;

    &:hover {
      color: var(--button-disabled-text-color);
      background-color: var(--button-disabled-color);
      border-color: var(--button-disabled-border-color);
    }
  }
}