.container {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 36px 24px;
  background-color: white;
  border-radius: 8px;

  .table {

    th {
      padding: 6px 12px !important;
      font-size: 14px;
      font-weight: 400 !important;
    }

    td {
      padding: 6px 12px !important;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .count {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}