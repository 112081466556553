
.file-list-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding-left: 24px;
  //border-left: 2px solid #eaeaea;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 18px;
}

.accessible {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;


  .radio-group {
    box-sizing: border-box;
    padding-top: 6px;

    .radio {
      margin-right: 30px;
    }
  }

  .accessible-search-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;

    .input {
      width: 180px;
      margin-left: 15px;
    }
  }
}

.accessible-add {
  margin: 0;
}

.file-list {
  width: 100%;
  padding-bottom: 24px;
  margin-top: 12px;

  .file-item {
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid #f38b8b;

    .add-loading {
      width: 100%;
    }

    .files {
      padding-bottom: 10px;
      border-bottom: 1px solid #e1e1e1;

      .origin-files {
        display: flex;
        align-items: center;
        margin: 7px 0;


        .origin-file-name {
          display: inline-block;
          max-width: 80%;
          padding: 2px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon {
          margin-right: 6px;
        }

        .delete-icon {
          margin-left: 10px;
          color: #818181;
          cursor: pointer;

          &:hover {
            color: var(--lotte-red);
          }
        }
      }
    }


    .file-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-size: 16px;
      color: #3e3e3e;
      cursor: pointer;


      .icon {
        margin-right: 8px;
        font-size: 18px;

        &:last-child {
          margin-left: auto;
        }
      }
    }

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin: 10px 0;
      font-size: 14px;
      font-weight: 300;
      color: #636363;

      .file-content {
        font-weight: 300;
        line-height: 18px;
        white-space: pre-wrap;
      }

      .file {
        display: flex;
        align-items: center;
        max-width: 90%;
        padding: 2px 0;
        margin-top: 7px;
        overflow: hidden;
        color: #3e3e3e;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        .file-icon {
          margin: 0 5px;
        }

        &:hover {
          color: var(--lotte-red);
        }
      }
    }

    .file-button-container {
      width: 100%;
      text-align: right;
    }
  }
}

.table {

  th {
    padding: 6px 12px !important;
    font-size: 14px;
    font-weight: 400 !important;
    vertical-align: middle !important;
  }

  td {
    padding: 6px 12px !important;
    font-size: 14px;
    font-weight: 300;
  }

  .vertical {
    vertical-align: middle;
  }
}
