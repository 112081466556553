.item {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: stretch;
  width: 100%;
  border: 0.5px solid #d0d0d0;

  & > * {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-name {
    width: 120px;
    min-width: 120px;
    padding: 6px;
    color: white;
    background-color: #686868;

    .question-name-container {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & > span {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }

  .item-value {
    flex-grow: 1;
    flex-wrap: wrap;
    align-content: space-around;

    .range-picker {
      width: 80%;
    }

    .input {
      width: 100%;
      margin: 0 20px;
    }

    .text-area {
      height: 85%;
    }

    .radio-group {
      width: 100%;
      margin-left: 10px;

      .radio {
        margin-left: 30px;
      }
    }

    .radio-group-target {
      width: 62%;
      margin: 10px 0 0 10px;

      .radio {
        margin-left: 30px;
      }
    }

    .text-area {
      line-height: 25px;
    }

    pre {
      width: 100%;
      height: 95% !important;
      overflow: auto;
      white-space: pre-wrap;
    }
  }

  // .item-value

  .justify {
    justify-content: space-between;

    .target-input-container {
      box-sizing: border-box;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
      width: 35%;
      height: 10%;
      margin: 10px 10px 0 0;
      overflow: visible;

      .input {
        box-sizing: border-box;
        width: 50%;
      }
    }
  }

  .text-left {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .upload-container {
    width: 60%;
    text-align: center;

    .upload {
      max-width: 70%;
      text-align: center;
    }

    .file-container {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 5px;
      position: relative;

      .cost {
        span {
          padding: 2px 10px;
        }
      }

      .upload-dragger {
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-x: hidden;

        p {
          font-size: 11px;
          color: #989898;
        }

        .file-list {
          width: 100%;
          height: 100%;
          overflow: auto;
          overflow-x: none;

          * {
            font-size: 12px;
          }

          .file-list-item {
            padding: 0;
          }

          h4 {
            width: 100%;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }


      }
    }
  }

  .upload-container-detail {
    box-sizing: border-box;
    align-content: flex-start;
    width: 60%;
    padding: 20px;
    text-align: center;

    .upload {
      max-width: 90%;
      margin-top: 20px;
      text-align: center;
    }

    .default-file {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      overflow: hidden;
      line-height: 40px;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid rgb(202, 202, 202);
      transition: 0.3s all;

      &:hover {
        border-color: var(--lotte-red);
      }
    }
  }

  .upload-container-detail-answer {
    box-sizing: border-box;
    align-content: center;
    width: 60%;
    padding: 10px;
    text-align: center;

    .upload {
      max-width: 90%;
      margin-top: 20px;
      text-align: center;
    }

    .default-file {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      overflow: hidden;
      line-height: 40px;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid rgb(202, 202, 202);
      transition: 0.3s all;

      &:hover {
        border-color: var(--lotte-red);
      }
    }

    .list-container-answer {
      width: 100%;
      height: 100%;

      p {
        font-size: 11px;
        color: #989898;
      }

      .file-list {
        width: 100%;
        height: 100%;
        overflow-x: hidden;

        * {
          font-size: 12px;
        }

        .file-list-item {
          padding: 0;
        }

        h4 {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .file-list-item {
        height: 35px;
      }
    }
  }

  .padding-container {
    padding: 10px;
  }
}

.span {
  grid-column: 1 / span 2;
}
