.info {
    font-size: 20px;
    margin-top: 20px;
}

.detail {
    width: 100%;
    display: flex;
    gap: 5px;
    margin: 10px 0 30px;

    .list {
        flex-grow: 1;
        height: 100%;
        border: 1px solid #aeaeae;
        .item {
            box-sizing: border-box;
            padding: 8px 10px;
            border-bottom: 1px solid #aeaeae;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;

            &:last-child {
                border: none;
            }
        }
        .title {
            background-color: #686868;
            color: white;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 1px;
        }
        .empty {
            background-color: #bfbfbf;
        }
    }

    .no-work-container {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        border: none;

        .no-work {
            width: 100%;
            background-color: rgb(201, 66, 66);
        }
        .no-work-item {
            box-sizing: border-box;
            width: 10%;
            border: 1px solid #aeaeae;

            &:last-child {
                border: 1px solid #aeaeae;
            }
        }
    }
}

.modal-container {
    padding: 20px;
}
