.container {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 24px 24px;
    background-color: white;
    border-radius: 8px;

    .table {
        th {
            padding: 6px 12px !important;
            font-size: 14px;
            font-weight: 400 !important;
            vertical-align: middle !important;
        }

        td {
            padding: 6px 12px !important;
            font-size: 14px;
            font-weight: 300;
        }

        .vertical {
            vertical-align: middle;
        }
    }

    .label {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 400;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 3px;

            input {
                text-align: right;
            }
        }

        .upload {
            height: 30px;
            font-size: 12px;
            color: #5b5b5b;
            box-sizing: border-box;

            span {
                padding: 0;
            }
            .upload-text {
                width: 250px;
                box-sizing: border-box;
                padding: 0 10px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .pagination-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }
}
