.container {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  min-width: 1200px;
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;

  &.mobile {
    min-width: unset;
    max-width: unset;

    .logo-container {
      padding: 10px 16px 10px 16px;
    }
  }

  .logo-container {
    box-sizing: border-box;
    display: flex;
    float: left;
    width: 220px;
    height: 100%;
    padding: 10px 16px 10px 0;

    a {
      align-self: center;
    }

    .logo {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .contents-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    float: right;
    width: calc(100% - 260px);
    height: 100%;

    &.mobile {
      justify-content: flex-start;
    }

    .list {
      display: flex;
      gap: 20px;
      height: 100%;

      .item {
        position: relative;
        box-sizing: border-box;
        align-items: center;
        margin: 0 12px;
        vertical-align: middle;

        &>a>.name {
          font-size: 16px;
          line-height: 71px;
          letter-spacing: 1px;
        }

        &:hover>a>.name {
          color: var(--lotte-red);
        }

        .sub-item-container {
          position: absolute;
          left: 50%;
          display: none;
          width: max-content;
          padding: 18px 36px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 2px 8px #0000001f;
          transform: translateX(-50%);

          &:hover {
            display: block;
          }

          .sub-item {
            margin-top: 16px;

            .name {
              font-weight: 400;

              &:hover {
                color: var(--lotte-red);
              }
            }

            &.sub-2 {
              margin-top: 12px;
              //margin-left: 8px;

              .name {
                font-weight: 300;
              }
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }

        a {
          display: inline-block;
          height: 100%;
          color: unset;
          text-decoration: none;

          &:hover {
            font-weight: bold;
          }

          &:hover + .sub-item-container {
            display: block;
          }
        }

        .icon {
          font-size: 16px;
        }

        .name {
          font-size: 16px;
          color: #333;
        }

        .menu-button {
          padding: 6px 8px;
          line-height: 64px;
          cursor: pointer;
          background: none;
          border: none;

          .icon {
            font-size: 22px;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .status-container {
    padding: 10px 16px;

    .x-icon {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      .bar1, .bar2, .bar3 {
        width: 27px;
        height: 3px;
        margin: 6px 0;
        background-color: #333;
        transition: 0.4s;
      }

      &.active .bar1 {
        transform: translate(0, 9px) rotate(-45deg);
      }

      &.active .bar2 {
        opacity: 0;
      }

      &.active .bar3 {
        transform: translate(0, -9px) rotate(45deg);
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .quick-menu {
    display: none;
  }
}


.logout-button {
  padding: 12px;
  font-size: 16px;
  color: #333;
  background: unset;
  border: unset;
}

.full-nav-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full-nav {
  position: absolute;
  top:  72px;
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  max-width: 1400px;
  height: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px #0000001f;
  transition: .6s ease height;

  &.active {
    height: unset;
    padding: 16px 24px;
  }

  &>ul {
    display: flex;

    &>li {
      flex: 1;
      font-weight: 500;

      &>a {
        display: inline-block;
        width: 90%;
        padding-bottom: 14px;
        border-bottom: 2px solid var(--lotte-red);

        &>.name {
          font-size: 18px;
        }
      }
    }
  }

  a {
    color: #333;
    text-decoration: none;
  }

  .name {

    &:hover {
      color: var(--lotte-red);
    }
  }
}

.sub-menu-container {
  margin-top: 24px;

  li {
    margin-top: 12px;

    &:first-child {
      margin-top: unset;
    }

    .name {
      font-weight: 400;
    }

    &.sub {
      margin-top: 8px;
      margin-left: 6px;

      .name {
        font-weight: 300;
      }
    }
  }
}
