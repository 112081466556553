.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;


  .range-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;

    .range-input {
      width: 45%;
    }
  }

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.table-container {
  width: 100%;
  margin-top: 26px;

  th {
    text-align: center !important;
  }

  td {
    text-align: center;

    p {
      font-weight: 300;
    }
  }

  .table-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      border: 1px solid var(--button-primary-highlight-color);
      background: var(--button-primary-color);
      color: black;
      transition: none;

      &:hover {
        border: 1px solid var(--button-primary-highlight-color);
        background-color: var(--button-primary-highlight-color);
        color: black;
      }
    }
  }

  .rotate-icon {
    margin-left: 8px;
    color: #8d8d8d;
    cursor: pointer;

    &:hover {
      color: var(--lotte-red);
    }
  }
}

