.option-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f1f1f1;
    padding: 20px 10px;
    box-sizing: border-box;

    .label {
        margin-right: 10px;
    }

    .date-picker {
        margin-right: auto;
    }
}

.table-container {
    margin-top: 30px;
    & > * {
        padding: 0;
    }
}

.date-info {
    font-size: 20px;
    margin-right: auto;
}
