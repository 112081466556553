@font-face {
  font-family: "TheJamsil";
  font-weight: 100;
  src: url("./fonts/The_Jamsil_1.ttf") format("truetype");
}

@font-face {
  font-family: "TheJamsil";
  font-weight: 300;
  src: url("./fonts/The_Jamsil_2.ttf") format("truetype");
}

@font-face {
  font-family: "TheJamsil";
  font-weight: 400;
  src: url("./fonts/The_Jamsil_3.ttf") format("truetype");
}

@font-face {
  font-family: "TheJamsil";
  font-weight: 600;
  src: url("./fonts/The_Jamsil_4.ttf") format("truetype");
}

@font-face {
  font-family: "TheJamsil";
  font-weight: 700;
  src: url("./fonts/The_Jamsil_5.ttf") format("truetype");
}

@font-face {
  font-family: "TheJamsil";
  font-weight: 900;
  src: url("./fonts/The_Jamsil_6.ttf") format("truetype");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  padding: 0;
  margin: 0;
  font: inherit;
  font-family: TheJamsil, sans-serif;
  font-size: 100%;
  font-weight: 400;
  letter-spacing: -0.02em;
  vertical-align: baseline;
  border: 0;
}



/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

html, body, #root {
  min-height: 100vh;
  font-family: TheJamsil, sans-serif;
  word-break: keep-all;
  word-wrap: break-word;
  background-color: var(--site-background-color);
}

.ant-picker-dropdown {
  z-index: 15000 !important;
}

.no-header .ant-picker-header {
  display: none;
}

.no-header .ant-picker-body {
  padding-top: 15px !important;
}

.ant-message {
  z-index: 14000;
}


#root.mobile * {
  user-select: none;
}

.ant-spin-fullscreen {
  z-index: 16000;
}
