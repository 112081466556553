.container {
  display: flex;
  gap: 24px;
}

.label {
  display: block;
  margin-bottom: 12px;
  margin-left: 6px;
  font-size: 14px;
}