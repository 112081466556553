.table {
    width: 100%;

    .table-header {
        width: 100%;
        display: grid;
        grid-template-columns:
            minmax(10px, 1fr) minmax(20px, 2fr) minmax(20px, 2fr) minmax(45px, 3.5fr) minmax(15px, 1.5fr) minmax(
                40px,
                4fr
            )
            minmax(45px, 3.5fr)
            minmax(40px, 3fr) minmax(40px, 3fr) minmax(100px, 6fr);

        .table-column {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(243, 243, 243);
            padding: 15px;
            box-sizing: border-box;
            border-left: 0.5px solid rgb(213, 213, 213);
            border-bottom: 0.5px solid rgb(213, 213, 213);

            &:last-child {
                border-right: 0.5px solid rgb(213, 213, 213);
            }
        }
    }

    .table-body {
        width: 100%;
        font-size: 14px;
        .table-row {
            width: 100%;
            display: grid;
            grid-template-columns:
                minmax(10px, 1fr) minmax(20px, 2fr) minmax(20px, 2fr) minmax(45px, 3.5fr) minmax(15px, 1.5fr) minmax(
                    40px,
                    4fr
                )
                minmax(45px, 3.5fr)
                minmax(40px, 3fr) minmax(40px, 3fr) minmax(100px, 6fr);

            & > * {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0.5px solid rgb(213, 213, 213);
                box-sizing: content-box;
                font-weight: 300 !important;
            }

            .input-cell {
                box-sizing: border-box;
                padding: 0 10px;

                input {
                    text-align: right;
                }
            }
            .double-cell {
                align-content: space-between;
                flex-wrap: wrap;

                .extra-cell {
                    width: 100%;
                    min-height: 90px;
                    height: 50%;
                    font-weight: 300 !important;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;

                    justify-content: center;
                    padding: 0 10px;
                    &:first-child {
                        border-bottom: 1px solid rgb(213, 213, 213);
                    }

                    .text-area {
                        height: 80%;
                        max-height: 100%;
                        resize: vertical;
                    }

                    .cost-disabled {
                        background-color: rgb(244, 244, 244);
                        color: black;
                    }
                }
                input {
                    text-align: right;
                }
                .cost {
                    justify-content: flex-end;
                }
            }

            .cost {
                justify-content: flex-end;
            }
        }
    }
}
