.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: backdrop-filter 0.5s ease;
  backdrop-filter: blur(0);

  &.active {
    backdrop-filter: blur(2px);
  }

  .inner {
    position: fixed;
    right: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 36px;
    margin-left: auto;
    overflow: auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateX(20%);

    &.normal {
      width: 45%;
      min-width: 600px;
    }

    &.large {
      width: 65%;
      min-width: 800px;
    }

    &.active {
      opacity: 100%;
      transform: translateX(0);
    }
  }

  .contents-container {
    flex: 1;
  }

  .button-container {
    display: inline;
    // margin-bottom: 36px;
    text-align: right;
  }
}

.loading {
  width: 100%;
  height: 90%;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  margin-top: 24px;
  padding-bottom: 24px;
  background-color: white;


  .info-top-wrapper {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 18px;
    font-size: 18px;
    // color: ;
  }

  .table {
    width: 100%;
    border-collapse: collapse;

    tr,
    th,
    td {
      padding: 14px 8px;
      border: 0.5px solid #d0d0d0;
      text-align: center;
      vertical-align: middle;
    }

    th {
      // background-color: #686868;
      background-color: #d9534f;
      color: white;
    }

    td {
      font-weight: 300;
    }

    .image-cell {
      max-width: 100%;
      max-height: 100%;
      text-align: center;
      vertical-align: middle;
      background-color: #ffffff
    }
  }
}

.info-right {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
