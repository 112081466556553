.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: backdrop-filter .5s ease;
  backdrop-filter: blur(0);

  &.active {
    backdrop-filter: blur(2px);
  }

  .inner {
    position: fixed;
    right: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 36px;
    margin-left: auto;
    overflow: auto;
    background: #FFF;
    border-radius: 12px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.5);
    opacity: 0;
    transition: all .5s ease;
    transform: translateX(20%);

    &.normal {
      width: 45%;
      min-width: 600px;
    }

    &.large {
      width: 65%;
      min-width: 800px;
    }

    &.active {
      opacity: 100%;
      transform: translateX(0);
    }
  }

  .contents-container {
    flex: 1;
  }

  .button-container {
    margin-top: 36px;
    text-align: right;
  }
}

.close-button-wrapper {
  position: absolute;
  top: 24px;
  right: 36px;
}