.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.chart-container {
  box-sizing: border-box;
  display: flex;
  gap: 24px;
  padding: 24px 36px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 4px;

  .chart {
    position: relative;
    flex: 1;
    align-self: center;
    width: 20%;
    height: 300px;

    label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}