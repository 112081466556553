.content {
  width: 100%;
  background-color: white;
  padding: 20px 10px;
  box-sizing: border-box;


  .icon {
    margin-left: 10px;
    color: #8e8e8e;

    &:first-child {
      margin-left: 15px;
    }

    &:hover {
      color: var(--lotte-red);
    }
  }

  .update-input {
    display: inline-block;
    width: 200px;
    height: 90%;
    box-sizing: border-box;
    padding: 3px 10px;
    margin: 0;
  }
}

