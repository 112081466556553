.wrapper {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .progress {
    width: 85%;
    height: 100%;
    background-color: #ececec;
    border-radius: 15px;
    transition: all 0.5s;
  }

  .percent {
    width: 15%;
    height: 100%;
    text-align: right;
    padding-top: 2px;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 20px;

    span:last-child {
      font-size: 14px;
      padding-left: 2px;
    }
  }
}