.list {
    width: 100%;
    margin-top: 32px;

    .item {
        width: 100%;
        margin-top: 16px;
        font-weight: 300;
    }
}

.description {
    padding: 12px 18px;
    font-size: 14px;
    background-color: var(--site-background-color);

    p {
        line-height: 1.6;
    }
}

.form-container {
    margin-top: 24px;

    .name {
        font-size: 18px;
    }

    .title {
        font-size: 16px;
    }

    .radio-container {
        margin-top: 24px;
    }

    .note {
        margin-top: 12px;
    }
}

.table {
    width: 100%;
    margin-top: 24px;
    border-collapse: collapse;

    th {
        padding: 12px 8px;
        vertical-align: middle;
    }

    td {
        padding: 4px 8px;
        vertical-align: middle;
    }

    tr {
        td {
            text-align: center;
        }
    }
}
