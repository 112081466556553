.detail-content {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: repeat(10, minmax(25px, 1fr)) minmax(75px, 2fr);

  .text-area {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .text-area-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    white-space: pre-wrap;
    overflow: auto;
    font-size: 14px;
    font-weight: 300;
  }

  .two-line-text {
    display: flex;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;

    & > span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    & > span:first-child {
      margin-bottom: 3px;
    }
  }

  .input {
    width: 100%;
    box-sizing: border-box;
    margin: 0 20px;
  }

  .time-picker {
    text-align: center;
  }

  .icon-arrow {
    margin: 0 20px;
  }

  .duty-title {
    text-align: center;
    line-height: 20px;
  }

  .duty-input {
    width: 30%;
  }
}

