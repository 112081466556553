.container {
  display: flex;
  gap: 36px;
  width: 100%;

  .list {
    width: fit-content;

    .item {
      min-width: 300px;
      padding: 8px 12px;
      margin: 8px 0;
      font-size: 16px;
      font-weight: 300;
      cursor: pointer;
      border: 2px solid #ccc;
      border-radius: 6px;

      &.selected {
        border-color: var(--lotte-red);
      }
    }
  }
}