.form-container {
    width: 100%;
    margin-top: 26px;

    .list {
        width: 100%;

        .item {
            display: flex;
            width: 100%;
            margin-bottom: 24px;

            .label {
                width: 120px;
                line-height: 32px;
            }

            .field {
                box-sizing: border-box;
                flex: 1;
            }
        }
    }
}

.thumbnail {
    max-width: 100%;
    max-height: 400px;
}
