.input-list {
  width: 100%;


  .input-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .input-label {
      margin-right: 12px;
      width: 80px;
    }

    .input {
      width: 300px;
    }
  }
}