.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 6px;
    overflow: auto;
    overflow-x: hidden;
    color: #a6a6a6;
    user-select: none;
    background: #252525;

    .menu-container {
        box-sizing: border-box;
        flex: 1;
        padding-right: 12px;

        .list {
            width: 100%;
            margin-bottom: 26px;
        }

        .item {
            box-sizing: border-box;
            width: 100%;
            padding-left: 16px;
            margin-top: 12px;
        }

        .name {
            box-sizing: border-box;
            display: flex;
            flex: 1;
            padding: 4px 6px 4px 0;
            font-size: 18px;
            color: unset;
            text-decoration: unset;
            cursor: pointer;

            &.current-page {
                position: relative;
                color: #ececec;

                &::before {
                    position: absolute;
                    top: 50%;
                    right: 9px;
                    display: block;
                    width: 4px;
                    height: 18px;
                    content: "";
                    background-color: var(--lotte-red);
                    outline: none;
                    transform: translateY(-50%);
                }
            }

            span {
                flex: 1;
            }

            &:hover,
            &:focus {
                color: #ececec;
            }

            .icon {
                width: 18px;
                margin-right: 8px;
                font-size: 16px;
            }

            .toggle-icon {
                align-self: flex-end;
            }
        }
    }

    .help-center-container {
        padding: 20px 16px;
        font-size: 16px;
        line-height: 23px;
        vertical-align: baseline;
        border-top: 2px solid #202020;

        .inquiry-button {
            font-size: 14px;
            border-bottom: 1px solid #a6a6a6;
            cursor: pointer;
            &:hover {
                color: var(--button-signature-color);
                border-color: var(--button-signature-color);
            }
        }
    }
}
