.container {
  width: 100%;

  h2 {
    margin-top: 24px;
    font-size: 18px;
  }

  .image-container {
    box-sizing: content-box;
    height: 300px;
    margin-top: 12px;
    overflow: hidden;
    line-height: 300px;
    text-align: center;
    border: 1px solid #595959;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}