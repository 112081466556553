.loading {
  // margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.form-container {
  width: 100%;
  height: 95%;
  margin-top: 20px;
  overflow: auto;

  .detail-content {
    display: grid;
    grid-template-rows: minmax(25px, 1fr) minmax(100px, 3fr) minmax(25px, 2fr);
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    width: 100%;
    height: 60%;

    .content {
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 300;
      white-space: pre-wrap;
      overflow: auto;
      line-height: 20px;
    }
  }

  .input {
    box-sizing: border-box;
    width: 100%;
    margin: 0 10px;
  }

  .text-area {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    resize: none;
  }
}
