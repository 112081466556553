.section {
  padding: 16px 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  background-color: #FFF;

  .table {
    box-sizing: border-box;
    width: 100%;

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    td {
      padding-top: 6px;
      padding-bottom: 6px;

      input[type="text"] {
        box-sizing: border-box;
        display: inline-block;
        width: 50%;
        padding: 4px 8px;
        font-size: 16px;
        border: 1px solid #bdbdbd;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .set-na {
        box-sizing: border-box;
        display: inline-block;
        width: 60px;
        padding: 4px 8px;
        font-size: 16px;
        border: 1px solid #bdbdbd;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.form {

  .checkFormName {
    margin-bottom: 4px;
    font-size: 22px;
    font-weight: 600;
  }

  .regionName {
    margin-bottom: 18px;
    font-weight: 300;
  }
}

.subject {

  h4 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
  }
}

.remark-area {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 24px;
  background-color: #fff;

  &-title {
    padding-top: 12px;
    padding-bottom: 8px;
    margin-left: -1px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    background-color: #bdbdbd60;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    font-size: 18px;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
  }
}

.change-keyboard {
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 16px;
  font-size: 24px;
  background-color: #dcdded;
  border: unset;
  border-radius: 1000px;
  box-shadow: 0 0 10px 0 rgba(133, 129, 129, 0.5);
}

.image-upload {
  display: block;
  margin-top: 8px;
}

.image-container {
  display: block;
  width: 70px;
  height: 70px;
  margin-top: 8px;
  vertical-align: middle;

  img {
    width: 100%;
    height: 100%;
  }
}

.preview-image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20000;
  background-color: rgba(119, 119, 119, 0.6);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 80%;
    max-height: 80%;
    transform: translate(-50%, -50%);
  }
}
