.content {
    .table {
        margin-bottom: 30px;
        th {
            padding: 6px 12px !important;
            font-size: 14px;
            font-weight: 400 !important;
        }

        td {
            padding: 6px 12px !important;
            font-size: 14px;
            font-weight: 300;
        }

        .inputs {
            text-align: left;
        }

        .selects {
            width: 90%;
            text-align: left;
        }
    }
}
