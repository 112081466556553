.inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
        font-size: 24px;
        line-height: 1.2;
    }

    .close-button {
        font-size: 28px;
        color: #686868;
    }
}

.list {
    width: 100%;
    margin-top: 26px;

    .item {
        display: flex;
        width: 100%;
        margin-bottom: 24px;

        .label {
            width: 120px;
            line-height: 32px;
        }

        .field {
            box-sizing: border-box;
            flex: 1;
        }
    }
}
