:root {
    --lotte-red: #F1291C;
    --site-background-color: #f4f4f4;
    --signature-color: #d9534f;

    /* button signature */
    --button-signature-text-color: #FFF;
    --button-signature-color: #d9534f;
    --button-signature-highlight-color: var(--lotte-red);
    --button-signature-highlight-with-alpha-color: #d2322d25;

    /* button primary */
    --button-primary-text-color: #333;
    --button-primary-color: #fff;
    --button-primary-highlight-color: #ccc;
    --button-primary-highlight-with-alpha-color: #cccccc16;


    /* button disabled */
    --button-disabled-text-color: #666;
    --button-disabled-color: #ccc;
    --button-disabled-border-color: #999;
}