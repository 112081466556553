.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom, #fff9f9, #ffffff);
}

.not-found__title {
    font-size: 8rem;
    font-weight: bold;
    color: var(--signature-color);
}

.not-found__message {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.not-found__button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: var(--signature-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.not-found__button:hover {
    background-color: var(--lotte-red);
}
