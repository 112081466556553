.question {
    width: 100%;
    margin: 10px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .question-input {
        width: 90%;
    }
}
