.list {
  width: 100%;

  .item {
    width: 100%;
    margin-top: 16px;

    .title {
      margin-top: 24px;
      font-size: 20px;
    }

    .text {
      margin-top: 12px;
      margin-bottom: 16px;
      font-size: 18px;
    }

    .table {
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-weight: 300;
      border-collapse: separate;
      border: 1px solid black;
      border-radius: 4px;

      th, td {
        padding: 16px 8px;
        vertical-align: middle;
      }

      td.category {
        width: 60px;
      }

      td.center {
        width: 80px;
        text-align: center;
      }

      .input {
        width: 100px;

        input {
          width: 100%;
        }
      }
    }
  }
}