.swal2-title {
padding-top: 38px;
  font-size: 24px;
}


.swal2-actions {
  margin-top: 26px;

  [type="button"] {
    font-family: TheJamsil, sans-serif;
    font-size: 14px;
  }
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 96%;
  max-width: 400px;
  padding-bottom: 18px;
}

.swal2-container {
  z-index: 20000;
}