.container {
  width: 100%;
  height: 100%;

  .button {
    display: block;
    width: 100%;
    padding: 48px 52px;
    margin-bottom: 32px;
    font-family: TheJamsil, sans-serif;
    font-size: 32px;
    text-align: left;
    background: #fff;
    border: none;
    border-radius: 24px;

    span {
      margin-left: 18px;
    }
  }
}