.container {
  width: 100%;
  padding-top: 36px;
  display: grid;
  grid-template-rows: repeat(2, minmax(30px, 1fr)) minmax(100px, 3fr) minmax(60px, 2fr);
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  height: 800px;

  .text-area {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    resize: none;
    color: black !important;
    line-height: 18px;
    font-weight: 300;
  }

  .content {
    line-height: 18px;
    font-size: 14px;
    font-weight: 300;
    word-break: break-word;
  }

  .text-area-text {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: auto;
  }

  .two-line-text {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;

    & > span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    & > span:first-child {
      margin-bottom: 3px;
    }
  }

  .input {
    box-sizing: border-box;
    width: 100%;
    margin: 0 10px;

    color: black !important;
  }

  .uploading-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}
