.container {
  width: 100%;

  .title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 600;
  }

  .desc {
    margin-bottom: 24px;
    font-weight: 300;
    line-height: 1.2;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .btn-with-icon {
      padding: 4px;
      font-size: 24px;
      border: none;
    }
  }

  .date {
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  .table {
    width: 100%;
    margin-top: 32px;
    font-size: 16px;
    border-collapse: collapse;

    tr {
      border-top: 1px solid #777;
      border-bottom: 1px solid #777;
    }

    thead {

      th {
        padding: 14px 6px;
        font-weight: 400;
        text-align: center;
      }
    }

    tbody {

      td {
        padding: 14px 6px;
        font-weight: 300;
        vertical-align: middle;

        &.center {
          text-align: center;
        }
      }
    }
  }
}

.reportName {
  display: flex;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;

  .reportTitle {
    flex: 1;

    .award {
      margin-bottom: -2px;
      margin-left: 8px;
      color: #16ba16;
    }
  }

  .reportCount {
    margin-left: 46px;
    font-size: 16px;
    font-weight: 500;
    color: #676767;

    &.done {
      color: #16ba16;
    }

    span {
      margin-right: 8px;
    }
  }
}

.regionName {
  margin-top: 3px;
  margin-bottom: 8px;
  margin-left: 28px;
  font-size: 16px;
  font-weight: 500;
}

.notiTime {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 300;
  color: #676767;

  span {
    margin-right: 8px;
  }
}

.done {
  color: #16ba16;
}

.check-time-list {
  display: block;
  width: 100%;

  .check-time {
    padding: 14px 0;
    margin-top: 22px;

    .time {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 1px;
    }

    .check-list {
      display: block;
      width: 100%;

      .check {
        padding: 26px 14px;
        margin-top: 12px;
        background-color: #fff;
        border-radius: 6px;

        .report-name {
          font-size: 18px;
          vertical-align: middle;

          .category {
            font-size: 16px;
          }
        }

        .region-name {
          margin-top: 6px;
          font-size: 16px;
          color: #8d8d8d;
        }
      }
    }
  }
}