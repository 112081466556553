.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.table-container {
  width: 100%;
  margin-top: 26px;

  .table-row {
    cursor: pointer;
  }

  .comment-count {
    font-weight: 300;
    color: #8d8d8d;
    margin-left: 5px;
    font-size: 12px;
  }

  .multiple {
    color: red;
  }
}

.table-label-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
