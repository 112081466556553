$header-height: 71px;
$sidebar-width: 260px;

.container {

  .header-container {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: $header-height;
    background-color: #fff;
    border-bottom: 1px solid #F1291C;
    box-shadow: 0 2px 8px #0000001f;
  }

  .sidebar-container {
    position: fixed;
    top: $header-height;
    bottom: 0;
    z-index: 10000;
    width: $sidebar-width;
    transition: transform .4s ease-in-out;

    &.opened-sidebar {
      transform: translateX(-$sidebar-width);
    }
  }

  .contents {

    &-container {
      //padding-top: $header-height;
    }

    &-inner {
      box-sizing: border-box;
      width: 100%;
      min-width: 1200px;
      max-width: 1400px;
      padding: 20px;
      margin: 0 auto;

      &.mobile {
        min-width: unset;
        max-width: unset;
      }
    }
  }
}
