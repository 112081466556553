.loading {
  width: 100%;
  height: 90%;
  // margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  width: 100%;
  height: 90%;
  margin-top: 20px;
  overflow: auto;

  .detail-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: repeat(11, minmax(25px, 1fr)) minmax(75px, 2fr);

    .text-area {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    .text-area-text {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 10px;
      white-space: pre-wrap;
      overflow: auto;
      font-size: 14px;
      font-weight: 300;
    }

    .two-line-text {
      display: flex;
      align-content: space-between;
      justify-content: center;
      flex-wrap: wrap;

      & > span {
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      & > span:first-child {
        margin-bottom: 3px;
      }
    }

    .input {
      width: 100%;
      box-sizing: border-box;
      margin: 0 20px;
    }

    .cost-reason {
      font-size: 15px;
      font-weight: 300;
      padding: 0 7px;
      box-sizing: border-box;
      line-height: 18px;
    }
  }

  // 취합내용
  .member {
    grid-template-rows: repeat(12, minmax(25px, 1fr)) minmax(75px, 2fr);
  }

  .subtitle {
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;

    .subtitle-text {
      margin-right: auto;
    }
  }
}
