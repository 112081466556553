.container {
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    height: 100%;
    padding-top: 36px;
    margin: 0 auto;

    .logo-container {
        text-align: center;

        img {
            width: 240px;
        }
    }

    .form-container {
        box-sizing: border-box;
        width: 95%;
        padding: 12px;
        margin: 24px auto auto;
        background-color: #fff;
        border-radius: 4px;

        h1 {
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1.1px;
        }

        .list {
            width: 100%;

            .item {
                display: flex;
                width: 100%;
                margin-bottom: 24px;

                .label {
                    width: 80px;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 32px;
                }

                .field {
                    box-sizing: border-box;
                    flex: 1;
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }
    }

    .message {
        padding: 0 36px;
        margin-top: 12px;
        color: #666;
        text-align: center;
    }

    .button-container {
        margin-top: 24px;

        .button {
            width: 100%;
            padding: 12px;
            font-size: 16px;
            color: #fff;
            background-color: var(--lotte-red);
            border: unset;
        }
    }
}

.thumbnail {
    max-width: 100%;
    max-height: 400px;
}
