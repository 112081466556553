.loading {
  // margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.form-container {
  width: 100%;
  margin-top: 20px;

  .question-content {
    display: grid;
    grid-template-rows: repeat(2, minmax(25px, 1fr)) minmax(100px, 3fr) minmax(25px, 2fr);
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    width: 100%;
    height: 500px;

    .content {
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 300;
      white-space: pre-wrap;
      overflow: auto;
      line-height: 20px;
    }

    .create-user-id {
      font-size: 14px;
      color: #afafaf;
      margin-left: 2px;
    }
  }

  .register {
    grid-template-rows: minmax(25px, 1fr) minmax(100px, 3fr) minmax(25px, 2fr);
  }

  .text-area {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    resize: none;

    color: black !important;
  }

  .text-area-text {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: auto;
  }

  .two-line-text {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;

    & > span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    & > span:first-child {
      margin-bottom: 3px;
    }
  }

  .input {
    box-sizing: border-box;
    width: 100%;
    margin: 0 10px;

    color: black !important;
  }

  .subtitle {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 0 10px 0;
    font-size: 18px;

    .subtitle-text {
      margin-right: auto;
    }
  }

  .comment-container {
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 15px;

    .input-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 70px;

      .comment-text-area {
        width: 80%;
        height: 100%;
        margin-right: auto;
        resize: none;
      }

      .button {
        width: 9%;
        height: 100%;
      }

      .confirm-button {
        color: white;
        background-color: var(--button-signature-color);
        border-color: var(--button-signature-color);

        &:hover {
          background-color: var(--button-signature-highlight-color);
        }
      }

      .add-file-button {
        padding: 0;
        margin-right: 5px;
        color: rgb(39, 39, 39) !important;
        background-color: white;

        &:hover {
          background-color: var(--button-primary-highlight-color);
          border-color: transparent;
        }

        .upload {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 70px;

          div {
            width: 100%;
          }

          .upload-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 70px;
          }
        }
      }
    }

    .file-info {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: 10px;
      font-size: 12px;

      .file-name {
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .remove-icon {
        margin-left: 20px;
        font-size: 15px;
        cursor: pointer;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  // 답글 달기

  .none-comment {
    width: 100%;
    text-align: center;
  }

  .comment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    border-top: 1px solid #afafaf;

    .comment-name {
      font-size: 14px;
    }

    .comment-id {
      margin-right: auto;
      margin-left: 5px;
      font-size: 12px;
      color: #afafaf;
    }

    .comment-delete {
      font-size: 12px;
      color: red;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .comment-date {
      margin-left: 10px;
      font-size: 14px;
    }

    .comment-content {
      width: 100%;
      margin-top: 8px;
      font-weight: 300;
      line-height: 22px;
      white-space: pre-wrap;
    }

    .comment-file {
      max-width: 95%;
      margin-top: 10px;
      overflow: hidden;
      font-size: 12px;
      color: #a4a4a4;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: var(--lotte-red);
      }

      .comment-file-icon {
        margin-right: 5px;
      }
    }
  }
}
