.section {
  font-size: 16px;

  .table {
    margin-top: 12px;
    margin-right: 4px;
    border-collapse: collapse;

    th {
      //padding: 0 8px;
      text-align: center;
    }

    td {
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;
      white-space: nowrap;

      input[type="text"] {
        box-sizing: border-box;
        display: inline-block;
        width: 60px;
        padding: 4px 8px;
        font-size: 14px;
        text-align: center;
        border: 1px solid #bdbdbd;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .set-na {
        box-sizing: border-box;
        display: inline-block;
        width: 40px;
        padding: 4px 8px;
        font-size: 14px;
        border: 1px solid #bdbdbd;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.form {

  .checkFormName {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
}

.subject {

  &.wrapper-1 {
    margin-bottom: 28px;

    &>h4 {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      background-color: #bdbdbd80;
      border-top: 1px solid #bdbdbd;
      border-bottom: 1px solid #bdbdbd;
    }
  }

  &.wrapper-2 {

    &>h4 {
      padding-top: 12px;
      padding-bottom: 8px;
      margin-bottom: 12px;
      margin-left: -1px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      background-color: #bdbdbd60;
    }
  }

  .subject-container {
    display: flex;
    align-items: flex-end;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      background-color: #F5F5F5;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #555;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }

    &.wrapper-1 {
      background-color: #fff;

      h4 {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

.notiTime {
  padding: 0 10px;
}

.value {
  width: 100%;
  min-width: 50px;
  padding: 0 10px;
}

.quick {
  position: sticky;
  top: 71px;
  right: 0;
  z-index: 100;
  margin-bottom: 24px;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #e4e4e4;
  border-bottom: 16px solid var(--site-background-color);

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }

  a {
    display: inline-block;
    height: 100%;
    padding: 24px 16px;
    color: inherit;
    text-decoration: unset;
  }
}

.remark-area {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 24px;
  background-color: #fff;

  &-title {
    padding-top: 12px;
    padding-bottom: 8px;
    margin-left: -1px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    background-color: #bdbdbd60;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    font-size: 18px;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
  }
}