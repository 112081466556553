.container {
    box-sizing: border-box;
    width: 100%;
    height: 20%;
    border: 1px solid rgb(188, 188, 188);
    display: flex;
    margin-bottom: 1%;
}

.index {
    width: 70px;
    height: 100%;
    background-color: rgb(146, 146, 146);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    color: white;
}

.content {
    flex: 1;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;

    .info {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgb(188, 188, 188);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .question {
            padding: 0 10px;
        }

        .not-applicable {
            width: 20%;
            height: 100%;
            border-left: 1px solid rgb(188, 188, 188);

            display: flex;
            align-items: center;
            justify-content: space-between;

            :first-child {
                background-color: rgb(146, 146, 146);
                width: 60%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
            }
            .check-box {
                width: 40%;
                height: 40%;
            }
        }
    }

    .textarea-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        box-sizing: border-box;

        .text-area {
            width: 100%;
            height: 100%;
            resize: none;
        }
    }
}
