.title-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .title {
        margin-right: auto;
    }
}

.date-container {
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);

    .date-picker {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgb(205, 205, 205);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;

        .tilde {
            margin: 0 15px;
        }

        .icons {
            margin: 0 25px;
            font-size: 23px;
            color: #a6a6a6;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
                color: var(--lotte-red);
            }
        }
    }

    .weekly-container {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        .weekly {
            height: 80px;
            display: flex;
            align-content: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            color: rgb(38, 38, 38);
            cursor: pointer;
            transition: all 0.4s;

            &:hover {
                background-color: #dfdfdf;
            }

            .day {
                font-size: 18px;
                width: 100%;
                margin-bottom: 10px;

                .today {
                    font-size: 13px;
                    color: #797979;
                    margin-left: 3px;
                }
            }
            .dow {
                width: 100%;
                font-size: 22px;
            }
        }
        .sat {
            color: rgb(59, 118, 228);
        }
        .sun {
            color: rgb(208, 0, 0);
        }
    }
}

.content {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    grid-auto-rows: auto;
    align-items: start;
}
