.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.table-container {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  width: 100%;
  margin-top: 26px;
  background: white;

  .table-label {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      font-size: 18px;
    }
  }
}

.file-list-container {
  box-sizing: border-box;
  padding: 24px;
}
