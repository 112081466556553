.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.card {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px #0000001a;

  &.check-status {
    grid-column: 1 / span 2;
  }

  &.merge {
    grid-column: 2 / span 2;
  }

  &.cost-integrated {
    grid-column: 2 / span 2;
  }

  &.calendar {
    grid-row: span 2;
    //grid-column: 2 / span 2;
  }

  .loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: #fff;
    transition: .4s all ease;
    transform: translateY(100%);
    z-index: 1;

    &.active {
      transform: translateY(0);
    }

    .loading-text {
      align-self: center;
      width: 100%;
      text-align: center;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 24px 0;
    font-size: 18px;
    color: var(--button-signature-color);

    small {
      font-size: 0.8em;
    }

    .title-icon {
      margin: 0 0 auto auto;
      font-size: 17px;
      cursor: pointer;


      &:hover {
        color: var(--button-signature-highlight-color);
      }
    }
  }

  .clickable {
    cursor: pointer;

    &:hover {
      color: var(--lotte-red);
    }
  }

  .body {
    padding: 24px;
  }
}


.list {

  .item {
    display: flex;
    margin-bottom: 12px;
    cursor: pointer;
    transition: .4s all ease;

    &:hover {
      transform: translateY(-1px);
    }

    .board-title {
      flex: 1;
      font-weight: 300;
      line-height: 20px;

      .inquiry-status {
        padding: 2px 8px;
        margin-right: 3px;
        font-size: 12px;
        background: #ffd7d7;
        border-radius: 5px;
      }

      .etc {
        background: #dfdfdf;
      }
    }

    .board-date {
      align-self: center;
      margin-left: 12px;
      font-size: 14px;
      color: #bdbdbd;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.empty {
  margin-top: 14px;
  margin-bottom: 22px;
  text-align: center;
}

.check-analytics {
  display: flex;
  gap: 40px;

  .analytics-area {
    flex: 1;


    .analytics-list {

      .analytics-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .label {
          width: 100%;
          box-sizing: border-box;
          padding-left: 20%;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .label-icon {
            font-size: 8px;
            margin-right: 4px;
          }

          .label-value {
            font-size: 16px;
            margin: 0 3px;
          }

          .highlight {
            color: var(--signature-color);
          }


          span {
            font-size: 14px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }


        .analytics-item-title {
          flex: 1;
          font-size: 18px;
          color: var(--button-signature-color);

          .analytics-item-title-icon {
            margin-left: 8px;
            font-size: 17px;
          }

          &:hover {
            color: var(--button-signature-highlight-color);
          }
        }

        .analytics-item-desc {
          margin-left: 8px;
          font-weight: 300;
        }

        .analytics-item-tag {
          padding: 2px 6px;
          margin-right: 4px;
          margin-left: 4px;
          font-size: 14px;
          font-weight: 300;
          background-color: rgba(186, 186, 186, 0.38);
          border-radius: 20px;
        }

        .analytics-item-chart {
          width: 100%;
          height: 12px;
          overflow: hidden;
          background-color: #d6d6d6;
          border-radius: 20px;

          .analytics-item-chart-bar {
            height: 100%;
            background-color: var(--lotte-red);
          }
        }
      }
    }

  }

  .elevator-area {
    width: 35%;

    .elevator-title {
      flex: 1;
      font-size: 18px;
      color: var(--button-signature-color);
    }

    .elevator-chart-wrapper {
      height: 200px;

      .elevator-chart-text {
        position: relative;
        width: 100px;
        transform: translate(-50%, -50%);
        bottom: 50%;
        left: 50%;
        text-align: center;
        pointer-events: none;

        p {
          padding: 5px 0;

          &:first-child {
            border-bottom: 1px solid #b8b8b8;
          }

          &:nth-of-type(2) {
            .elevator-chart-value {
              color: var(--signature-color);
            }
          }

        }

        .elevator-chart-value {
          font-size: 18px;
        }

        .elevator-chart-penalty {
          font-size: 13px;
          background: #dcdcdc;
          padding: 3px 10px;
          border-radius: 10px;

          .elevator-chart-value {
            font-size: 16px;
          }
        }


      }
    }

  }
}


.user-info {
  color: black;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  font-weight: 300;
}

.user-name {
  padding-top: 10px;
}

.user-label {
  background: #dcdcdc;
  padding: 3px 5px;
  border-radius: 5px;
  color: black;
  font-weight: 300;
  margin-right: 10px;

  &:nth-of-type(2) {
    margin-left: 10px;
  }
}

.table {

  th {
    padding: 6px 12px !important;
    font-size: 14px;
    font-weight: 400 !important;
    vertical-align: middle !important;
  }

  td {
    padding: 6px 12px !important;
    font-size: 14px;
    font-weight: 300;
  }

  .vertical {
    vertical-align: middle;
  }
}

.quick-list {
  display: flex;
  gap: 8px;

  .quick-item {
    display: inline-block;
    flex: 1;
    padding: 8px 16px;

    .quick-menu-icon {
      text-align: center;

      .icon {
        font-size: 40px;
      }
    }

    .quick-menu-text {
      margin-top: 8px;
      text-align: center;
    }
  }
}

.weather-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 3px;

  .weather {
    border: 1px solid #e1e1e1;
    border-radius: 4px;

    .weather-image {
      width: 100%;
    }

    .temperature {
      font-size: 11px;
      text-align: center;
    }

    .info {
      margin: 5px 0 3px;
    }

    .date {
      color: #aeaeae;
      margin-bottom: 5px;
    }
  }
}

