.title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.options-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 12px;

  .container {
    display: inline-block;

    .label {
      display: block;
      margin-bottom: 12px;
      margin-left: 6px;
      font-size: 14px;
    }
  }
}

.date-container {
  width: 100%;
  border-bottom: 1px solid rgb(255, 33, 33);

  .date-picker {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgb(205, 205, 205);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;

    .tilde {
      margin: 0 15px;
    }

    .icons {
      margin: 0 25px;
      font-size: 23px;
      color: #a6a6a6;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: var(--lotte-red);
      }
    }
  }

  .weekly-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .weekly {
      height: 80px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      color: rgb(38, 38, 38);
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: #dfdfdf;
      }

      .day {
        font-size: 18px;
        width: 100%;
        margin-bottom: 10px;

        .today {
          font-size: 13px;
          color: #797979;
          margin-left: 3px;
        }
      }

      .dow {
        width: 100%;
        font-size: 22px;
      }
    }

    .sat {
      color: rgb(59, 118, 228);
    }

    .sun {
      color: rgb(208, 0, 0);
    }
  }
}


.table-label {
  margin: 15px 0 10px;
}

.table-wrapper {
  width: 100%;
  margin-bottom: 30px;

  .table-row {
    width: 100%;
    display: grid;
    grid-template-columns: 8% 6% repeat(7, 1fr) 8%;
    border-bottom: 1px solid #e8e8e8;

    .sat {
      color: rgb(59, 118, 228);
    }

    .sun {
      color: rgb(208, 0, 0);
    }

    & > div {
      box-sizing: border-box;
      padding: 6px 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .button {
        border: none;
        background: var(--button-signature-color);
        color: white;
        transition: none;

        &:hover {
          background-color: var(--button-signature-highlight-color);
          color: white;
        }
      }
    }
  }

  .table-header {
    background-color: #f4f4f4;

  }
}

.button-container {
  display: flex;
  align-self: flex-end;
}