.container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 12000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    transition: backdrop-filter 0.5s ease;
    backdrop-filter: blur(0);

    &.active {
        backdrop-filter: blur(2px);
    }

    .inner {
        position: fixed;
        right: 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 55%;
        min-width: 800px;
        height: 100%;
        padding: 24px 36px;
        margin-left: auto;
        overflow: auto;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: all 0.5s ease;
        transform: translateX(20%);

        &.active {
            opacity: 100%;
            transform: translateX(0);
        }
    }

    .contents-container {
        flex: 1;

        .half-container {
            display: inline-block;
            width: calc(50% - 10px);
            margin-right: 20px;
            vertical-align: top;

            &:last-child {
                margin-right: unset;
            }
        }
    }

    .button-container {
        text-align: right;
    }
}

.form-container {
    width: 100%;
    margin-top: 26px;

    .list {
        width: 100%;

        .item {
            display: flex;
            width: 100%;
            margin-bottom: 24px;

            .label {
                width: 120px;
                line-height: 32px;
            }

            .field {
                box-sizing: border-box;
                flex: 1;
            }
        }
    }
}

.thumbnail {
    max-width: 100%;
    max-height: 600px;
}
