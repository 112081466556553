.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px;
  background: linear-gradient(to bottom, #fff9f9, #fff);
}

.not-found__title {
  font-size: 8rem;
  font-weight: bold;
  color: var(--signature-color);
}

.not-found__message {
  margin: 1rem 0;
  font-size: 1.5rem;
}

.not-found__button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  background-color: var(--signature-color);
  border: none;
  border-radius: 5px;
}

.not-found__button:hover {
  background-color: var(--lotte-red);
}
