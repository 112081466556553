.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}



.contents-container {
  position: relative;

  .button-container {
    position: absolute;
    top: 18px;
    right: 12px;
  }

  .table-container {
    width: 100%;
  }
}