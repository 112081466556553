.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: backdrop-filter 0.5s ease;
  backdrop-filter: blur(0);

  &.active {
    backdrop-filter: blur(2px);
  }

  .inner {
    position: fixed;
    right: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 65%;
    min-width: 600px;
    height: 100%;
    padding: 24px 36px;
    margin-left: auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateX(20%);

    &.active {
      opacity: 100%;
      transform: translateX(0);
    }
  }

  .contents-container {
    flex: 1;
    height: 90%;
  }

  .button-container {
    text-align: right;
  }
}

.loading {
  width: 100%;
  height: 90%;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  width: 100%;
  height: 90%;
  margin-top: 26px;
  overflow: auto;

  .detail-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: repeat(4, minmax(50px, 1fr)) minmax(75px, 3fr) minmax(100px, 4fr) minmax(75px, 3fr);
  }

  // 취합내용

  .data {
    grid-template-rows: repeat(4, minmax(50px, 1fr)) minmax(150px, 3fr) minmax(200px, 4fr) minmax(100px, 2fr) 2fr;

    .question {
      width: 100%;
      border: 1px solid red;
      height: 50px;
    }
  }
}

.reference {
  margin-bottom: 10px;
  color: #575757;
}