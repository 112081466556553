.wrapper {
  width: 100%;

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .icon {
      font-size: 22px;
      color: var(--button-signature-color);
      cursor: pointer;

      &:hover {
        color: var(--button-signature-highlight-color);
      }
    }
  }

  .calendar-body {
    border: 1px solid #ececec;

    .sun {
      color: #c81d1d
    }

    .sat {
      color: #4475cc;
    }

    .dow-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      border-bottom: 0.2px solid #dc8f8f;

      span {
        padding: 8px 0;
        text-align: center;
        font-size: 13px;
        border-right: 1px solid #ececec;

        &:last-child {
          border: none;
        }
      }
    }

    .day-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }
  }
}


.day {
  box-sizing: border-box;
  padding: 7px;
  height: 38px;
  font-size: 12px;
  cursor: pointer;

  border-top: 1px solid #ececec;
  border-right: 1px solid #ececec;

  &:nth-child(7n) {
    border-right: none;
    color: #4475cc;
  }

  // 일
  &:nth-child(7n-6) {
    color: #c81d1d;
  }

  // 마지막 주
  &:nth-child(-n+7) {
    border-top: none;
  }


  &:hover {
    background-color: #ffeeee;
  }

  .icon {
    font-size: 5px;
    color: var(--button-signature-color);
    margin-right: 2px;
  }
}

.out-of-month {
  color: rgba(0, 0, 0, 0.4);

  // 일
  &:nth-child(7n-6) {
    color: rgba(200, 29, 29, 0.4);
  }

  &:nth-child(7n) {
    border-right: none;
    color: rgba(68, 117, 204, 0.4);
  }
}

.today {
  span {
    background-color: #ffdbdb;
    padding: 2px 4px;
    box-sizing: border-box;
    border-radius: 1000px;
  }
}

.selected {
  background-color: #ffd4d4;
}

.calendar-footer {
  margin-top: 5px;
  height: 130px;
  overflow: auto;

  .schedule-list {
    width: 100%;

    .schedule-item {
      width: 100%;
      border-bottom: 1px solid #ececec;
      padding: 6px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #f4f4f4;

      }

      .title {
        display: inline-block;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        font-size: 8px;
        color: var(--button-signature-color);
        margin-right: 12px;
      }


      .update-icon {
        font-size: 15px;
        color: #aeaeae;
        cursor: pointer;

        &:hover {
          color: #ff0c0c;
        }
      }

      .trash-icon {
        font-size: 14px;
        color: #aeaeae;
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: #ff0c0c;
        }
      }

      .info {
        p:first-child {
          font-size: 14px;
          margin-bottom: 2px;

          span:first-child {
            display: inline-block;
            width: 20px;
            text-align: center;
            margin-right: 10px;
          }
        }

        p:last-child {
          font-size: 11px;
          color: #aeaeae;

          span:first-child {
            display: inline-block;
            width: 20px;
            text-align: center;
            margin-right: 10px;
          }

        }
      }
    }
  }
}