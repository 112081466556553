.container {
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    height: 100%;
    padding-top: 36px;
    margin: 0 auto;

    .title {
        padding-left: 12px;
        margin-top: 48px;
        font-size: 28px;
        font-weight: 500;

        span {
            font-size: 0.8em;
        }
    }

    .logo-container {
        text-align: center;

        img {
            width: 240px;
        }
    }

    .request-button {
        width: 90%;
        height: 50px;
        margin: 50px auto;
        border-radius: 5px;
        background-color: var(--button-signature-color);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: white;
    }

    .list {
        margin-top: 36px;

        .item {
            position: relative;
            padding: 16px 16px 4px;
            margin-bottom: 12px;
            background-color: #fff;

            & > span {
                position: absolute;
                top: -4px;
                right: 8px;
                width: 100px;
                font-size: 12px;
                text-align: center;
                letter-spacing: 2px;
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
                box-shadow: 0 3px 6px 0 #aaa;

                &.REQUEST {
                    padding: 8px 0;
                    background: #34e6f6;
                }

                &.ING {
                    padding: 8px 0;
                    background: #f6e634;
                }

                &.COMPLETED {
                    padding: 8px 0;
                    background: #34f634;
                }
            }

            .half-container {
                display: flex;

                .half {
                    flex: 1;
                }
            }

            h2 {
                margin-bottom: 6px;
            }

            p {
                margin-bottom: 12px;
                font-size: 18px;
                font-weight: 300;
            }
        }
    }
}

.empty {
    margin-top: 48px;
    text-align: center;
}
