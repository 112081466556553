.container {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #aeaeae;
    cursor: pointer;

    &:hover {
        border-color: var(--lotte-red);
    }

    .list {
        width: 100%;

        .item {
            box-sizing: border-box;
            padding: 8px 10px;
            border-bottom: 1px solid #aeaeae;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;

            &:last-child {
                border: none;
            }
        }
        .title {
            background-color: #686868;
            color: white;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 1px;
        }
        .empty {
            background-color: #bfbfbf;
        }
    }
}
