.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;


  .list-form-container {
    width: 60px;
    height: 30px;
    display: flex;
    justify-content: space-between;

    .list-form-button {
      height: 100%;
      width: auto;
      aspect-ratio: 1 / 1;
      border: 1px solid #8d8d8d;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #dcdcdc;

      &:hover {
        background: #f1f1f1;
      }

      .icon {
        font-size: 20px;
      }
    }

    .active {
      color: var(--lotte-red);
      background: white;
    }
  }

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.content-block {
  width: 100%;
  box-sizing: border-box;
  padding-top: 16px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 15px;

  .building-card {
    height: 130px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s all;
    box-shadow: 0 0 5px #d3d3d3;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 5px var(--lotte-red);

      .icon {
        color: #7e7e7e;
      }
    }

    .icon {
      width: 30%;
      font-size: 45px;
      color: #dfdfdf;
      transition: 0.3s all;
      padding: 0 15px;
      box-sizing: border-box;
    }

    .building-info-container {
      width: 70%;
      max-width: 70%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      padding-right: 10px;
      box-sizing: border-box;

      .building-name {
        text-align: center;
        font-size: 20px;
        color: #6a6a6a;
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      .building-info {
        width: 100%;
        text-align: left;
        color: #7a7a7a;
        margin-bottom: 10px;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .icons {
          margin-right: 3px;
        }
      }
    }
  }
}


.content-table {
  width: 100%;
  box-sizing: border-box;
  padding-top: 16px;


  .table {

    th {
      padding: 6px 12px !important;
      font-size: 14px;
      font-weight: 400 !important;
    }

    td {
      padding: 6px 12px !important;
      font-size: 14px;
      font-weight: 300;
    }
  }
}

