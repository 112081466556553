.container {
  display: inline-block;

  .label {
    display: block;
    margin-bottom: 12px;
    margin-left: 6px;
    font-size: 14px;
  }

  .select {
    width: 200px;
  }
}