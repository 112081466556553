.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.table-container {
  width: 100%;
}