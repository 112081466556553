.container {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;

  .background-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    animation: bg-change 20s infinite ease-in;

    &::after {
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      overflow: hidden;
      content: url("./bg_1.jpg") url("./bg_2.jpg") url("./bg_3.jpg") url("./bg_4.jpg");
    }
  }

  @keyframes bg-change {

    0%, 20% {
      background-image: url("./bg_1.jpg");
    }

    25%, 45% {
      background-image: url("./bg_2.jpg");
    }

    50%, 70% {
      background-image: url("./bg_3.jpg");
    }

    75%, 95% {
      background-image: url("./bg_4.jpg");
    }

    100% {
      background-image: url("./bg_1.jpg");
    }
  }

  .blur-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: radial-gradient(#1e1e1e40 1px, transparent 0);
    background-size: 3px 3px;
  }

  .login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 400px;
    padding: 68px 48px;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(26px);

    @media screen and (max-width: 460px) {
      width: 80%;
    }

    .logo-container {
      text-align: center;
      user-select: none;

      .logo {
        width: 80%;
        max-width: 200px;
      }
    }

    .text-container {
      margin-top: 14px;

      .text {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        letter-spacing: .08em;
      }
    }

    .form-container {
      margin-top: 40px;

      .input {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        padding: 6px 12px;
        margin-bottom: 12px;
        border: none;
        border-radius: 4px;
      }

      .error {
        margin-top: 12px;
        color: var(--lotte-red);
        text-align: center;
        visibility: hidden;

        &.on {
          visibility: unset;
        }
      }
    }

    .app-download {
      position: absolute;
      right: 12px;
      bottom: 12px;
      padding: 6px 8px;
      background-color: #fff;
      border: unset;
      width: 200px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .qr-image {
        height: 100%;
        margin-right: 10px;
        text-align: left;
      }

      .qr-info {
        font-size: 12px;
        text-align: left;
        margin-top: 4px;
        color: #8d8d8d;
      }
    }
  }
}

.app-download-popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #fff9;
  backdrop-filter: blur(26px);

  img {
    width: 80%;
    margin-top: 26px;
  }

  p {
    margin-top: 12px;


    a {
      padding: 4px 8px;
      font-family: TheJamsil, sans-serif;
      font-size: 14px;
      color: #000;
      text-decoration: unset;
      border: 1px solid black;
      border-radius: 4px;
    }

    button {
      padding: 4px 8px;
      margin-left: 14px;
      font-family: TheJamsil, sans-serif;
      font-size: 14px;
      background-color: unset;
      border: 1px solid black;
      border-radius: 4px;
    }
  }
}